import { Suspense } from "react";
import React from "react";

import Loading from "./Loading";

export function SuspenseHOC(WrappedComponent: React.FC) {
	function ComponentWithSuspense(props: React.ComponentProps<any>) {
		React.useEffect(() => {
			(function (w: any, d: any, s: any, l: any, i: any) {
				w[l] = w[l] || [];
				w[l].push({
					"gtm.start": new Date().getTime(),
					event: "gtm.js",
				});
				var f = d.getElementsByTagName(s)[0],
					j = d.createElement(s),
					dl = l !== "dataLayer" ? "&l=" + l : "";
				j.async = true;
				j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
				f.parentNode.insertBefore(j, f);
			})(window, document, "script", "dataLayer", "GTM-K3KW8982");
		}, []);
		return (
			<Suspense fallback={<Loading />}>
				<WrappedComponent {...props} />
			</Suspense>
		);
	}

	return ComponentWithSuspense;
}
